import { Divider, Form, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetTeamMMutation } from "src/app/services/management";
import { IOption } from "src/app/services/type";
import { updateTeams } from "src/app/slices/contingentSlice";
import { useAppDispatch, useTypedSelector } from "src/app/store";
import AddTeam from "src/pages/sub_info/team/addTeam";

interface Props {
  name?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  mode?: "multiple" | "tags";
}

function TeamFormItem({
  name = "user",
  required = false,
  className,
  disabled,
  mode,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [getTeam, { data }] = useGetTeamMMutation();
  const [options, setOptions] = useState<IOption[]>([]);
  const { teams } = useTypedSelector((state) => state.contingent);

  useEffect(() => {
    !teams && getTeam("limit=1000");
  }, []);

  useEffect(() => {
    if (data || teams) {
      let arr: IOption[] = [];
      (data?.results || teams)?.forEach((item) => {
        arr.push({
          label: item.first_name + " " + item.last_name,
          value: item.id.toString(),
        });
      });
      setOptions(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, teams]);

  return (
    <Form.Item
      name={name}
      label={`${t("common.user")}${required ? " *" : ""}`}
      rules={
        required ? [{ required: true, message: t("common.selectUser") }] : []
      }
      className={className || "full"}
    >
      <Select
        allowClear
        mode={mode}
        options={options}
        disabled={disabled}
        showSearch
        optionFilterProp="label"
        filterOption={(input, option: any) =>
          option?.label?.toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          optionA.label
            ?.toLowerCase()
            .localeCompare(optionB.label.toLowerCase())
        }
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <Space style={{ padding: "0 4px 4px" }}>
              <AddTeam
                customTitle={t("common.addUuser")}
                setEditableData={() => {}}
                handleGet={() =>
                  getTeam("limit=1000")
                    .unwrap()
                    .then((val) => {
                      dispatch(updateTeams(val.results));
                    })
                }
              />
            </Space>
          </>
        )}
      />
    </Form.Item>
  );
}

export default TeamFormItem;
