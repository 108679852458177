import { DatePickerProps, Form, Input, InputNumber } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IFilterJournal } from "src/app/services/finance/type";
import { useTypedSelector } from "src/app/store";
import ModalFooter from "src/components/common/modal/ModalFooter";
import { dateFormat } from "src/components/filter/FilterDate";
import AccountFormItem from "src/components/formItem/AccountFormItem";
import ContingentFormItem from "src/components/formItem/ContingentFormItem";
import CurrencyFormItem from "src/components/formItem/CurrencyFormItem";
import DateFormItem from "src/components/formItem/DateFormItem";
import DirectionFormItem from "src/components/formItem/DirectionFormItem";
import IntervalDateFormItem from "src/components/formItem/IntervalDateFormItem";
import SourceFormItem from "src/components/formItem/SourceFormItem";
import TeamFormItem from "src/components/formItem/TeamFormItem";

function JournalFilters() {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleMakeParams = (key: string, value: string) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const [form] = Form.useForm();
  const onFinish = (val: IFilterJournal) => {
    let { date, interval_date, ...rest } = val;
    if (date && !Array.isArray(date))
      handleMakeParams("date", date?.format("YYYY-MM-DD") || "");
    if (interval_date) {
      handleMakeParams(
        "from_date",
        interval_date[0]?.format("YYYY-MM-DD") || ""
      );
      handleMakeParams("to_date", interval_date[1]?.format("YYYY-MM-DD") || "");
    }

    for (const [key, value] of Object.entries(rest)) {
      handleMakeParams(key, String(value || ""));
    }
  };

  const { accounts } = useTypedSelector((state) => state.account);
  const [accountId, setAccountId] = useState<string[]>([]);

  useEffect(() => {
    if (accountId.length === 0) return;
    else if (accountId.length === 1) {
      let curr = accounts?.find((el) => el.id === +accountId[0])?.currency;
      if (curr) {
        form.setFieldsValue({ currency: [curr] });
      }
    } else {
      let result: string[] = [];
      for (let id of accountId) {
        let curr = accounts?.find((el) => el.id === +id)?.currency;
        if (curr && !result.includes(curr)) {
          result.push(curr);
        }
      }
      form.setFieldsValue({ currency: result });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, accounts]);

  useEffect(() => {
    let date_arr: DatePickerProps["value"][] = [];
    for (let key of Array.from(searchParams.keys())) {
      let value = searchParams.get(key);
      if (key === "date") {
        form.setFieldValue("date", dayjs(value, dateFormat));
      } else if (key === "from_date") {
        date_arr.unshift(dayjs(value, dateFormat));
      } else if (key === "to_date") {
        date_arr.push(dayjs(value, dateFormat));
      } else if (value?.includes(",")) {
        form.setFieldValue(key, value.split(",") || []);
        if (key === "account" && value) setAccountId(value.split(","));
      } else {
        form.setFieldValue(key, value || "");
        if (key === "account" && value) setAccountId([value]);
      }
      if (date_arr.length > 0) form.setFieldValue("interval_date", date_arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReset = () => {
    setSearchParams();
    form.resetFields();
  };
  return (
    <div className="journal-filters">
      <Form
        form={form}
        requiredMark={false}
        name="normal_login"
        className="form-form"
        onFinish={onFinish}
        layout={"vertical"}
      >
        <DateFormItem required={false} className="w-150" />
        <IntervalDateFormItem
          required={false}
          className="w-300"
          name="interval_date"
          label={t("common.intervalDate")}
        />

        <SourceFormItem
          className="w-250"
          required={false}
          allData
          mode="multiple"
        />

        <ContingentFormItem className="w-250" mode="multiple" />
        <DirectionFormItem className="w-250" mode="multiple" />

        <AccountFormItem
          mode="multiple"
          className="w-250"
          onChange={(val) => setAccountId(val as string[])}
        />
        <CurrencyFormItem
          className="w-150"
          disabled={accountId.length !== 0}
          mode="multiple"
        />

        <Form.Item name="income" label={t("common.income")} className="w-150">
          <Input type="number" />
        </Form.Item>

        <Form.Item name="outcome" label={t("common.outcome")} className="w-150">
          <Input type="number" />
        </Form.Item>

        <TeamFormItem className="w-250" mode="multiple" />
        <Form.Item
          name="month"
          label={t("common.whichMonth")}
          className="w-150"
          tooltip={{ title: t("common.whichMonthInfo") }}
        >
          <InputNumber min={1} type="number" style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item name="comment" label={t("common.comment")} className="w-250">
          <Input />
        </Form.Item>

        <ModalFooter
          onCancel={handleReset}
          okText={t("common.search")}
          cancelText={t("common.clear")}
        />
      </Form>
    </div>
  );
}

export default JournalFilters;
