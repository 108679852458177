import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetDirectionMutation } from "src/app/services/finance";
import { IOption } from "src/app/services/type";
import { useTypedSelector } from "src/app/store";

interface Props {
  required?: boolean;
  className?: string;
  label?: string;
  name?: string;
  mode?: "multiple" | "tags";
}

function DirectionFormItem({
  required = false,
  className,
  label,
  name = "direction",
  mode,
}: Props) {
  const { t } = useTranslation();
  const [getDirection, { data }] = useGetDirectionMutation();
  const [options, setOptions] = useState<IOption[]>([]);
  const { directions } = useTypedSelector((state) => state.direction);

  useEffect(() => {
    !directions && getDirection("limit=1000");
  }, []);

  useEffect(() => {
    if (data || directions) {
      let arr: IOption[] = [];
      (data?.results || directions)?.forEach((item) => {
        arr.push({ label: item.title, value: item.id.toString() });
      });
      setOptions(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, directions]);

  return (
    <Form.Item
      name={name}
      label={`${label ?? t("common.direction")}${required ? " *" : ""}`}
      rules={
        required
          ? [{ required: true, message: `${t("common.direction")}ni tanlang!` }]
          : []
      }
      className={className || "full"}
    >
      <Select mode={mode} allowClear options={options} />
    </Form.Item>
  );
}

export default DirectionFormItem;
