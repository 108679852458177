import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetAccountMutation } from "src/app/services/finance";
import { IOption } from "src/app/services/type";
import { useTypedSelector } from "src/app/store";

interface Props {
  required?: boolean;
  className?: string;
  mode?: "multiple" | "tags";
  onChange?: (val: any) => void;
}

function AccountFormItem({
  required = false,
  className,
  onChange,
  mode,
}: Props) {
  const { t } = useTranslation();
  const [getAccount, { data }] = useGetAccountMutation();
  const [options, setOptions] = useState<IOption[]>([]);
  const { accounts } = useTypedSelector((state) => state.account);
  useEffect(() => {
    !accounts && getAccount("limit=1000");
  }, []);
  useEffect(() => {
    if (data || accounts) {
      let arr: IOption[] = [];
      (data?.results || accounts)?.forEach((item) => {
        arr.push({ label: item.title, value: item.id.toString() });
      });
      setOptions(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, accounts]);

  return (
    <Form.Item
      name="account"
      label={`${t("common.account")}${required ? " *" : ""}`}
      rules={
        required ? [{ required: true, message: t("common.selectAccount") }] : []
      }
      className={className || "full"}
    >
      <Select
        allowClear
        mode={mode}
        options={options}
        onChange={onChange}
        showSearch
        optionFilterProp="label"
        filterOption={(input, option: any) =>
          option?.label?.toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          optionA.label
            ?.toLowerCase()
            .localeCompare(optionB.label.toLowerCase())
        }
      />
    </Form.Item>
  );
}

export default AccountFormItem;
