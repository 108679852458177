import { Checkbox, Form } from "antd";
import { useTranslation } from "react-i18next";

function SourceRequiredItems() {
  const { t } = useTranslation();

  return (
    <Form.Item
      name="checkbox"
      label={t("common.isSelectable")}
      className="full"
      //   valuePropName="checked"
    >
      <Checkbox.Group
        options={[
          {
            label: t("common.contingent"),
            value: "is_contingent",
          },
          {
            label: t("common.user"),
            value: "is_employee",
          },
        ]}
      />
    </Form.Item>
  );
}

export default SourceRequiredItems;
