import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import localeData from "dayjs/plugin/localeData";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import weekday from "dayjs/plugin/weekday";
import { useTypedSelector } from "src/app/store";
import "src/styles/variables.scss";
import { ProviderProps } from "../type";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

function AntConfigProvider({ children }: ProviderProps) {
  const { colors } = useTypedSelector((state) => state.layout);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colors.primary,
          fontSize: 16,
          fontFamily: "Poppins",
          colorBgTextActive: colors.color_white,
          colorText: colors.color_black,
        },
        components: {
          Modal: {
            borderRadius: 6,
            contentBg: colors.color_sidebar,
            colorIcon: colors.color_black,
          },
          Table: {
            colorFillAlter: colors.color_white,
            controlHeight: 40,
            colorText: colors.color_black,
            colorBgContainer: colors.color_sidebar,
            colorTextHeading: colors.color_black,
            colorBorderSecondary: colors.color_border,
            fontSize: 14,
            fontWeightStrong: 500,
            padding: 12,
            paddingContentVerticalLG: 10,
          },
          Menu: {
            activeBarBorderWidth: 0,
            fontSize: 16,
            colorBgContainer: colors.color_sidebar,
            colorPrimary: colors.primary,
            itemHoverBg: colors.color_sidebar,
            subMenuItemBg: colors.color_sidebar,
            colorBgElevated: colors.color_sidebar,
          },
          Input: {
            fontSize: 16,
            controlHeight: 38,
            colorText: colors.color_black,
            colorTextPlaceholder: colors.color_placeholder,
            colorPrimaryHover: colors.primary,
            colorBorder: colors.color_border,
            colorBgContainer: colors.color_white,
            colorBgContainerDisabled: colors.color_white,
            borderRadius: 6,
          },
          InputNumber: {
            fontSize: 16,
            controlHeight: 38,
            borderRadius: 6,
            colorTextPlaceholder: colors.color_placeholder,
            colorPrimaryHover: colors.primary,
            colorBorder: colors.color_border,
            colorBgContainer: colors.color_white,
            colorBgContainerDisabled: colors.color_white,
          },
          Select: {
            fontSize: 16,
            controlHeight: 38,
            controlHeightLG: 48,
            colorPrimary: colors.primary,
            colorPrimaryHover: colors.primary,
            controlItemBgActive: colors.color_white,
            colorBgElevated: colors.color_white,
            colorText: colors.color_black,
            colorTextPlaceholder: colors.color_placeholder,
            colorBorder: colors.color_border,
            colorBgContainer: colors.color_white,
            borderRadius: 6,
          },
          Checkbox: {
            colorPrimary: colors.color_green,
            colorPrimaryHover: colors.color_green,
          },
          DatePicker: {
            controlHeight: 38,
            colorTextDisabled: colors.primary,
            colorTextHeading: colors.primary,
            colorPrimary: colors.primary,
            colorBgElevated: colors.color_white,
            colorBgContainer: colors.color_white,
            colorText: colors.color_black,
            colorTextPlaceholder: colors.color_placeholder,
            colorBorder: colors.color_border,
          },
          Breadcrumb: {
            linkColor: colors.color_content_tertiary,
            lastItemColor: colors.color_black,
            separatorColor: colors.color_content_tertiary,
          },
          Popover: {
            colorBgElevated: colors.color_content_quaternary,
          },
          Dropdown: {
            colorBgElevated: colors.color_white,
          },
          Radio: {
            buttonBg: colors.color_white,
            colorPrimary: colors.color_green,
            colorPrimaryHover: colors.color_green,
          },
          Pagination: {
            colorPrimary: colors.primary,
            colorBorder: colors.color_border,
            colorPrimaryHover: colors.primary,
            colorBgContainer: colors.color_white,
          },
          FloatButton: {
            colorPrimary: colors.color_green,
            colorPrimaryHover: colors.color_green,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
}

export default AntConfigProvider;
