import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../footer";
import Header from "../header";
import AOS from "aos";
import "aos/dist/aos.css";
import "../explain/explain.scss";
import "./resources.scss";
import { useGetSiteInfoMutation } from "src/app/services/management";
import { Flex, Spin } from "antd";

export const ResourcesPage = () => {
  const { t } = useTranslation();
  const [getFunc, { data, isLoading }] = useGetSiteInfoMutation();

  const giveYoutubeId = (url: string) => {
    let id = url?.replace("https://youtu.be/", "")?.split("?")[0];
    return id;
  };

  useEffect(() => {
    getFunc();
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  return (
    <div className="home">
      <div className="background-lines-container">
        <div className="backline"></div>
        <div className="backline"></div>
        <div className="backline"></div>
        <div className="backline"></div>
        <div className="backline"></div>
        <div className="backline"></div>
        <div className="backline"></div>
      </div>
      <Header />

      <div className="section-explain resources">
        <div className="explain-wrap container">
          <h2 data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <span>{t("home.menu.menu6")} </span>
          </h2>

          {isLoading && (
            <Flex justify="center" align="center" style={{ marginTop: 64 }}>
              <Spin spinning />
            </Flex>
          )}

          <div className="explain-cards">
            <img
              className="background-effect"
              src={require("src/assets/img/home/gradient.png")}
              alt="background effect"
            />

            {data?.results?.map((card, i) => (
              <a href={card?.video_url} target="_blank">
                <div className={`img-card `} key={i}>
                  <div className="img-card-wrap">
                    {/* <img
                    src={require(`src/assets/img/home/${card.img}.png`)}
                    alt="card"
                    /> */}
                    <img
                      className="img-card-img"
                      src={`https://i.ytimg.com/vi/${giveYoutubeId(
                        card?.video_url
                      )}/hq720.jpg`}
                    />
                  </div>
                  <div className="img-card-detail">
                    <h4 className="line-clamp2">{t(card?.title)}</h4>
                    <p className="line-clamp2">{t(card?.description)}</p>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
