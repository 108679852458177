import { api } from "../api";
import {
  ILogin,
  ILoginRes,
  ISignUp,
  IProfile,
  IChat,
  IChatRes,
  IComment,
} from "./type";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    //User login endpoint
    login: build.mutation<ILoginRes, ILogin>({
      query: (body) => ({
        url: "/users/sign-in",
        method: "POST",
        body,
      }),
    }),
    //User sign up endpoint
    signUp: build.mutation<IProfile, ISignUp>({
      query: (body) => ({
        url: "/users/sign-up",
        method: "POST",
        body,
      }),
    }),
    //Get profile info endpoint
    getProfile: build.mutation<IProfile, void>({
      query: () => ({ url: "/users/profile" }),
    }),
    //Chat endpoint
    chat: build.mutation<IChatRes, IChat>({
      query: (body) => ({
        url: "/chat",
        method: "POST",
        body,
      }),
    }),
    //Comment endpoint
    comment: build.mutation<IComment, IComment>({
      query: (body) => ({
        url: "/register-request",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useGetProfileMutation,
  useChatMutation,
  useCommentMutation,
} = authApi;
