import { api } from "../api";
import { IBaseDataRes, IDeleteRes } from "../type";
import { IProfile, ISiteMenu } from "../users/type";
import {
  IAddContingent,
  IAddOrganization,
  IBranch,
  IBranchRes,
  IContingent,
  IContingentRes,
  IEditContingent,
  IEditOrganization,
  IOrganization,
  IOrganizationRes,
  IStorage,
  IStorageRes,
  ITeamEdit,
  ITeamLanding,
  ITeamRes,
} from "./type";

export const managementApi = api.injectEndpoints({
  endpoints: (build) => ({
    //Get organizaton info endpoint
    getOrganization: build.query<IOrganizationRes, void>({
      query: () => ({ url: `/management/company` }),
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: "Organizations" as const,
                id,
              })),
              { type: "Organizations", id: "LIST" },
            ]
          : [{ type: "Organizations", id: "LIST" }],
    }),
    //Add organization endpoint
    addOrganization: build.mutation<IOrganization, IAddOrganization>({
      query: (body) => ({
        url: "/management/company",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Organizations", id: "LIST" }],
    }),
    //Edit organization endpoint
    editOrganization: build.mutation<IOrganization, IEditOrganization>({
      query: (data) => {
        let { id, body } = data;
        return {
          url: "/management/company/" + id,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: [{ type: "Organizations", id: "LIST" }],
    }),
    //Delete organization endpoint
    deleteOrganization: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/management/company/" + id,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Organizations", id: "LIST" }],
    }),

    //Get team endpoint
    getTeamM: build.mutation<ITeamRes, string>({
      query: (url) => ({ url: "/management/team?" + url }),
    }),
    getTeam: build.query<ITeamRes, void>({
      query: () => ({ url: "/management/team" }),
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: "Team" as const,
                id,
              })),
              { type: "Team", id: "LIST" },
            ]
          : [{ type: "Team", id: "LIST" }],
    }),

    //Add team endpoint
    addTeam: build.mutation<IProfile, any>({
      query: (body) => ({
        url: "/management/team",
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: (post) => [{ type: "Team", id: "LIST" }],
    }),

    //Edit team endpoint
    editTeam: build.mutation<IProfile, ITeamEdit>({
      query: (data) => {
        const { id, formData } = data;
        return {
          url: `/management/team/${id}`,
          method: "PUT",
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: (post) => [{ type: "Team", id: "LIST" }],
    }),

    //Delete project endpoint
    deleteTeam: build.mutation<IDeleteRes, number>({
      query(id) {
        return {
          url: `/management/team/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (post) => [{ type: "Team", id: post?.id }],
    }),

    ////////// Contingent //////////

    //Get contingent endpoint
    getContingentAll: build.query<IContingentRes, void>({
      query: () => ({ url: `/management/contingent` }),
    }),
    getContingent: build.mutation<IContingentRes, string>({
      query: (params) => ({ url: `/management/contingent?${params}` }),
    }),
    //Add contingent endpoint
    addContingent: build.mutation<IContingent, IAddContingent>({
      query: (body) => ({
        url: "/management/contingent",
        method: "POST",
        body,
      }),
    }),
    //Edit contingent endpoint
    editContingent: build.mutation<IContingent, IEditContingent>({
      query: (data) => {
        let { id, body } = data;
        return {
          url: "/management/contingent/" + id,
          method: "PUT",
          body,
        };
      },
    }),
    //Delete contingent endpoint
    deleteContingent: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/management/contingent/" + id,
        method: "DELETE",
      }),
    }),
    //Get team for landing
    getTeamLanding: build.query<ITeamLanding[], void>({
      query: () => ({ url: `/team` }),
    }),

    ////////// Storage //////////

    //Get storage endpoint
    getStorage: build.mutation<IBaseDataRes<IStorageRes>, string>({
      query: (params) => ({ url: "/management/storage?" + params }),
    }),

    //Add storage endpoint
    addStorage: build.mutation<IStorageRes, IStorage>({
      query: (body) => ({
        url: "/management/storage",
        method: "POST",
        body,
      }),
    }),

    //Get Site info endpoint
    getSiteInfo: build.mutation<IBaseDataRes<ISiteMenu>, void>({
      query: () => ({ url: "/management/site-info" }),
    }),

    ////////// Branches //////////

    //Get Branches endpoint
    getBranches: build.mutation<IBaseDataRes<IBranchRes>, string>({
      query: (params) => ({ url: "/management/branches?" + params }),
    }),

    //Add Branches endpoint
    addBranches: build.mutation<IBranchRes, IBranch>({
      query: (body) => ({
        url: "/management/branches",
        method: "POST",
        body,
      }),
    }),

    //Selet branch
    selectBranches: build.mutation<
      { company: number },
      { company: number | string }
    >({
      query: (body) => ({
        url: "/management/branches/change-branch",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetOrganizationQuery,
  useAddOrganizationMutation,
  useEditOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetTeamMMutation,
  useGetTeamQuery,
  useAddTeamMutation,
  useEditTeamMutation,
  useDeleteTeamMutation,
  useGetContingentAllQuery,
  useGetContingentMutation,
  useAddContingentMutation,
  useEditContingentMutation,
  useDeleteContingentMutation,
  useGetTeamLandingQuery,
  //Storage
  useGetStorageMutation,
  useAddStorageMutation,
  useGetSiteInfoMutation,

  //branches
  useGetBranchesMutation,
  useAddBranchesMutation,
  useSelectBranchesMutation,
} = managementApi;
